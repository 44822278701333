/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, keywords, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `pl`,
  meta: [],
  keywords: [
    'zwyżka stalowa wola',
    'zwyżka rzeszów',
    'zwyżka mielec',
    'zwyżka sandomierz',
    'zwyżka wynajem',
    'usługi zwyżką rzeszów',
    'usługi zwyżką stalowa wola',
    'podnośnik koszowy teleskopowy',
    'wynajem',
    'podnośnik koszowy',
    'hydraulika',
    'pomoc drogowa',
    'holowanie',
    'autohol',
    'maszyny budowlane',
    'bojanów',
    'stalowa wola',
    'nowa dęba',
    'tarnobrzeg',
    'sandomierz',
    'podkarpacie',
    'rzeszów',
    'janów lubelski',
  ],
  description: `Wynajem wraz z operatorem: zwyżka, podnośnik koszowy, nożycówka, laweta. Prace budowlane i ogrodowe. Województwo podkarpackie - Stalowa Wola, Rzeszów, Tarnobrzeg, Sandomierz, Nisko, Kolbuszowa i okolice.`,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO
