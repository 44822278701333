import React from 'react'
import styled from 'styled-components'

const ContainerWrapper = styled.section`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  width: 85%;
  max-width: 1400px;
  text-align: left;

  img {
    margin: 0 0 20px;
    max-width: 100%;
  }

  p {
    margin-top: 0;
  }

  ${({ theme }) => theme.media.desktop} {
    width: 85vw;
    grid-template-columns: repeat(${props => props.columns}, 1fr);
    grid-column-gap: 30px;
  }
`

const Container = props => (
  <ContainerWrapper columns={props.columns}>{props.children}</ContainerWrapper>
)

export default Container
