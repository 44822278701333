import styled from 'styled-components'

const Button = styled.button`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  border: none;
  border-radius: 2px;
  padding: 10px 20px;
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.2em;
  text-decoration: none;
  text-transform: lowercase;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: ${({ theme }) => theme.colors.dark};
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.5);
  }

  ${({ theme }) => theme.media.desktop} {
    padding: 10px 20px;
  }
`

export default Button
