import React, { Component } from 'react'
import posed from 'react-pose'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { Link } from 'gatsby'

import Button from '../../components/Button/Button'

import CloseIcon from '../../assets/svg/close.svg'
import MenuIcon from '../../assets/svg/menu.svg'

const NavigationWrapper = styled.nav`
  z-index: ${({ theme }) => theme.zIndexes.navigationElements};
  list-style: none;
  position: fixed;
  top: 50px;
  right: 30px;
  margin: 0;
  padding: 0;

  ${({ theme }) => theme.media.desktop} {
    top: 50px;
    right: 50px;
    position: absolute;
  }
`

const NavigationList = styled.ul`
  list-style: none;
  position: absolute;
  top: -500px;
  right: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 50px 60px;
  background: ${({ theme }) => theme.colors.dark};

  ${({ theme }) => theme.media.desktop} {
    position: relative;
    top: auto;
    right: auto;
    flex-direction: row;
    padding: 0;
    background: none;
  }
`

const NavigationElement = styled.li`
  position: relative;
  margin: 0 0 20px;

  > a {
    position: relative;
  }

  &::before {
    z-index: 0;
    position: absolute;
    bottom: 10px;
    left: 20px;
    border: 0;
    border-radius: 2px 2px 0 0;
    width: 0;
    height: 21px;
    background: ${({ theme }) => theme.colors.primary};
    transition: width 0.35s ease-out;
    content: '';
  }

  &:hover::before {
    width: calc(100% - 40px);
  }

  ${({ theme }) => theme.media.desktop} {
    margin: 0;

    &::before {
      bottom: 12px;
    }
  }
`

const NavButton = styled(Button)`
  width: auto;
  border-radius: 0;
  padding: 0 20px;
  background: none;
  font-size: 1.4em;
  font-weight: 700;
  box-shadow: none;

  :hover {
    box-shadow: none;
    background: none;
  }

  ${({ theme }) => theme.media.desktop} {
    width: auto;
    height: 40px;
  }
`

const NavigationToggle = styled.button`
  display: block;
  width: 50px;
  height: 50px;
  outline: 0;
  border: 0;
  border-radius: 2px;
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  font-size: 2em;
  font-wieght: 800;
  cursor: pointer;

  ${({ theme }) => theme.media.desktop} {
    display: none;
  }
`

const StyledIcon = styled(SVG)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 8px 0 0;
  width: 30px;
  height: 30px;
  svg {
    width: 100%;
    height: 100%;
    path {
      fill: #ffffff;
      stroke: #ffffff;
      stroke-width: 2px;
    }
  }
`

const PosedMenu = posed.div({
  visible: {
    y: 500,
    transition: { duration: 800 },
  },
  hidden: {
    y: 0,
    transition: { duration: 800 },
  },
})

const Menu = styled(PosedMenu)`
  opacity: 1;
`

class Navigation extends Component {
  state = {
    visible: false,
  }

  toggleMenu = () => {
    this.setState(prevState => ({ visible: !prevState.visible }))
  }

  render() {
    const links = this.props.links
    return (
      <NavigationWrapper>
        <Menu pose={this.state.visible ? 'visible' : 'hidden'}>
          <NavigationList>
            {links.map((link, index) => (
              <NavigationElement key={index}>
                <NavButton as={Link} to={link.to}>
                  {link.name}
                </NavButton>
              </NavigationElement>
            ))}
          </NavigationList>
        </Menu>
        <NavigationToggle onClick={this.toggleMenu}>
          {this.state.visible ? (
            <StyledIcon src={CloseIcon} />
          ) : (
            <StyledIcon src={MenuIcon} />
          )}
        </NavigationToggle>
      </NavigationWrapper>
    )
  }
}

export default Navigation
