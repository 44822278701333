import React from 'react'
import styled from 'styled-components'

import Container from '../Container/Container'

const FooterWrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  max-width: ${({ theme }) => theme.media.max};
  margin: 0 auto;
  padding: 10vh 40px 20vh;
  background: center center no-repeat;
  background-color: ${({ theme }) => theme.colors.primary};
  background-image: url(${props => props.image});
  background-size: cover;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;

  ::before {
    z-index: 2;
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 50.75%;
    height: 50%;
    content: '';
    background: ${({ theme }) => theme.colors.white};
    pointer-events: none;
    transform: rotate(10deg);
    transform-origin: bottom left;
  }

  ::after {
    z-index: 2;
    position: absolute;
    bottom: 100%;
    right: 0;
    width: 50.75%;
    height: 50%;
    content: '';
    background: ${({ theme }) => theme.colors.white};
    pointer-events: none;
    transform: rotate(-10deg);
    transform-origin: bottom right;
  }

  > * {
    position: relative;
  }

  ${({ theme }) => theme.media.desktop} {
    padding: 20vh 20vw 30vh;
  }
`

const FooterMask = styled.div`
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.dark};
  opacity: 0.6;
`

const Footer = props => (
  <FooterWrapper image={props.image}>
    <FooterMask />
    <Container columns={2}>
      <div>
        <h4>Kontakt</h4>
        <p>tel +48 788 716 154</p>
        <p>mail szymonmarut@wp.pl</p>
        <p>www kamelzwyzka.pl</p>
      </div>
      <div>
        <h4>O nas</h4>
        <p>
          Szeroki zakres usług, terminowość, kompleksowa realizacja oraz
          partnerskie podejście to są rzeczy, które wyróżniają nas na rynku.
        </p>
        <h4>Cennik</h4>
        <p>
          W celu omówienia szczegółów realizacji usługi prosimy o kontakt
          telefoniczny. W trakcie rozmowy ustalimy szczegółowy plan, termin oraz
          cenę zlecenia.
        </p>
      </div>
    </Container>
  </FooterWrapper>
)

export default Footer
