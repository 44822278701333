import React, { Component } from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'

import PhoneIcon from '../../assets/svg/phone.svg'

const PhoneWrapper = styled.aside`
  z-index: ${({ theme }) => theme.zIndexes.phone};
  display: flex;
  list-style: none;
  position: fixed;
  top: 60vh;
  left: 100%;
  margin: 0;
  padding: 0;
  transform: translate3d(${({ visible }) => (visible ? '-100%' : '0')}, 0, 0);
  transition: transform 0.3s ease;

  ${({ theme }) => theme.media.desktop} {
    position: fixed;
  }
`

const PhoneToggle = styled.button`
  position: absolute;
  top: 0;
  right: 100%;
  display: inline-block;
  width: 50px;
  height: 50px;
  outline: 0;
  border: 0;
  border-radius: 2px 0 0 2px;
  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  font-size: 2em;
  font-wieght: 800;
  cursor: pointer;

  ${({ theme }) => theme.media.desktop} {
    width: 70px;
    height: 70px;
  }
`

const StyledIcon = styled(SVG)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 8px 0 0;
  width: 30px;
  height: 30px;
  svg {
    width: 100%;
    height: 100%;
    path {
      fill: #ffffff;
      stroke: #ffffff;
      stroke-width: 1px;
    }
  }

  ${({ theme }) => theme.media.desktop} {
    width: 40px;
    height: 40px;
  }
`

const PhoneNumber = styled.a`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  height: 50px;
  background: ${({ theme }) => theme.colors.dark};
  color: ${({ theme }) => theme.colors.white};
  text-decoration: none;

  ${({ theme }) => theme.media.desktop} {
    padding: 0 30px;
    height: 70px;
    font-size: 20px;
  }
`

class Phone extends Component {
  state = {
    visible: false,
  }

  togglePhone = () => {
    this.setState(prevState => ({ visible: !prevState.visible }))
  }

  render() {
    return (
      <PhoneWrapper visible={this.state.visible}>
        <PhoneToggle onClick={this.togglePhone}>
          <StyledIcon src={PhoneIcon} />
        </PhoneToggle>
        <PhoneNumber href="tel:+48788716154">788&nbsp;716&nbsp;154</PhoneNumber>
      </PhoneWrapper>
    )
  }
}

export default Phone
