import styled from 'styled-components'

const Header = styled.h2`
  width: 85vw;
  font-size: ${({ isBig }) => (isBig ? '30px' : '26px')};
  text-align: center;

  ${({ theme }) => theme.media.desktop} {
    width: 100%;
    font-size: ${({ isBig }) => (isBig ? '5em' : '3em')};
  }
`

export default Header
