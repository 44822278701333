import React, { Component } from 'react'
import styled from 'styled-components'

const HeroWrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  max-width: ${({ theme }) => theme.media.max};
  margin: 0 auto;
  padding: 20vh 30px 20vh;
  background: center center no-repeat;
  background-color: ${({ theme }) => theme.colors.primary};
  background-image: url(${props => props.image});
  background-size: cover;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;

  ::before {
    z-index: 2;
    position: absolute;
    top: 100%;
    right: 50%;
    width: 80%;
    height: 50%;
    content: '';
    background: ${({ theme }) => theme.colors.white};
    pointer-events: none;
    transform: rotate(10deg);
    transform-origin: top right;
  }

  ::after {
    z-index: 2;
    position: absolute;
    top: 100%;
    left: 50%;
    width: 80%;
    height: 50%;
    content: '';
    background: ${({ theme }) => theme.colors.white};
    pointer-events: none;
    transform: rotate(-10deg);
    transform-origin: top left;
  }

  > * {
    position: relative;
  }

  > h1 {
    margin-bottom: 0;
    font-size: 32px;
    line-height: 1.2;
  }

  ${({ theme }) => theme.media.desktop} {
    padding: 20vh 20vw 20vh;

    > h1 {
      margin-bottom: 0;
      font-size: 4em;
      line-height: 1.2;
    }
  }
`

const HeroMask = styled.div`
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.dark};
  opacity: 0.6;
`

class Hero extends Component {
  render() {
    return (
      <HeroWrapper image={this.props.image}>
        <HeroMask />
        {this.props.children}
      </HeroWrapper>
    )
  }
}

export default Hero
