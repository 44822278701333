import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components'

import { theme } from '../utils/theme'

import Logo from '../components/Logo/Logo'
import Phone from '../components/Phone/Phone'
import Navigation from '../components/Navigation/Navigation'
import Footer from '../components/Footer/Footer'

import footerImage from '../assets/images/zwyzka-nozycowa-huta-stalowa-wola.jpg'

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  html {
    overflow-x: hidden;
  }

  body {
    overflow-x: hidden;
    margin: 0;
    color: ${({ theme }) => theme.colors.dark};
    font-family: 'Montserrat', sans-serif;
    line-height: 1.6;
  }
`

const StyledWrapper = styled.div`
  overflow: hidden;
  display: ${props => (props.isFlex ? 'flex' : 'block')};
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 100vw;
  min-height: 100vh;
  background: ${({ theme }) => theme.colors.white};
`

const navigationLinks = [
  {
    name: 'Usługi',
    to: '/uslugi',
  },
  {
    name: 'Sprzęt',
    to: '/sprzet',
  },
  {
    name: 'Blog',
    to: '/blog',
  },
  {
    name: 'Cennik',
    to: '/cennik',
  },
  {
    name: 'Kontakt',
    to: '/kontakt',
  },
]

const Layout = ({ isFlex, noNav, children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <ThemeProvider theme={theme}>
          <>
            <GlobalStyle />
            <StyledWrapper isFlex>
              <Logo />
              {!noNav && <Navigation links={navigationLinks} />}
              {children}
              <Footer image={footerImage} />
              <Phone />
            </StyledWrapper>
          </>
        </ThemeProvider>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
