import React from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'
import { Link } from 'gatsby'

import Icon from '../../assets/svg/logo.svg'

const LogoWrapper = styled.header`
  z-index: ${({ theme }) => theme.zIndexes.navigationElements};
  list-style: none;
  position: absolute;
  top: 50px;
  left: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 60px;
  margin: 0;
  padding: 0;

  a {
    color: ${({ theme }) => theme.colors.white};
    text-decoration: none;
  }

  &::before {
    position: absolute;
    bottom: 10px;
    left: 0;
    border: 0;
    border-radius: 2px 2px 0 0;
    width: 0;
    height: 11px;
    background: ${({ theme }) => theme.colors.primary};
    transition: width 0.35s ease-out;
    content: '';
  }

  &:hover::before {
    width: 100%;
  }

  ${({ theme }) => theme.media.desktop} {
    left: 50px;
    width: 180px;
    height: 80px;

    &::before {
      bottom: 8px;
      height: 18px;
    }
  }
`

const StyledIcon = styled(SVG)`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 100px;
  svg {
    width: 100%;
    height: 100%;
    path {
      fill: #ffffff;
      stroke-color: #ffffff;
    }
  }

  ${({ theme }) => theme.media.desktop} {
    width: 180px;
    height: 150px;
  }
`

const Logo = () => (
  <LogoWrapper>
    <Link to="/">
      <StyledIcon src={Icon} />
    </Link>
  </LogoWrapper>
)

export default Logo
