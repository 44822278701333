import { colors } from './colors'

export const theme = {
  colors,
  font: {
    thin: 300,
    regular: 400,
    bold: 800,
  },
  media: {
    tablet: '@media (min-width: 728px)',
    desktop: '@media (min-width: 1024px)',
    max: '2200px',
  },
  zIndexes: {
    mainContent: 1000,
    phone: 1500,
    navigationElements: 2000,
    cookies: 3000,
    popUp: 5000,
  },
}
